import React from "react"
import { graphql } from "gatsby"
import {
  SEO,
} from '../components';
import {
  Layout,
  LayoutHeader,
  LayoutFooter,
  LayoutMain,
  Header,
  Footer,
  PageTitle,
} from '../ui-components';
import {
  Wrapper,
  Container,
  DescriptionMain,
} from '../components/mission-statement/mission-statement.styled';
import FeaturesFull from "../components/features/features-full";
import PreOrder from "../components/pre-order/pre-order";
import Specifications from "../components/specifications/specifications";
import BetritonFull from "../../content/betriton-full.json";
import { markdown2HTML } from '../utils';


const FullPage = ({ data }) => {
  const content = data.markdownRemark?.frontmatter || {};
  const siteTitle = `BeTRITON - Full version`
  const gallery = data.gallery.edges.map(v => v.node.childMarkdownRemark.frontmatter) || [];

  return (
    <>
      <SEO
        canonical="/ready-made"
        title={siteTitle}
      />
      <Layout id="layout" layout="simple">
        <LayoutHeader>
          <Header />
        </LayoutHeader>
        <LayoutMain>
          <Wrapper>
            <Container>
              <PageTitle custom dangerouslySetInnerHTML={{ __html: markdown2HTML(content.title) }} />
              <DescriptionMain dangerouslySetInnerHTML={{ __html: content.description }} />
            </Container>
          </Wrapper>
          <Wrapper paper>
            <Container>
              <FeaturesFull gallery={gallery} nospecs />
              <Specifications content={BetritonFull} />
            </Container>
          </Wrapper>
          <PreOrder />
        </LayoutMain>
        <LayoutFooter>
          <Footer />
        </LayoutFooter>
      </Layout>
    </>
  );
}

export default FullPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fileAbsolutePath: {regex: "/content/pages/betriton_full.md/"}) {
      id
      frontmatter {
        title
        description
      }
      html
    }
    gallery: allFile(filter: {absolutePath: {regex: "/content/gallery/betriton_full/"}},  sort: {fields: childrenMarkdownRemark___frontmatter___order, order: ASC}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              type
              youtube
              image{ 
                childImageSharp {
                  thumb: gatsbyImageData(
                    width: 187
                    height: 140
                    quality: 100
                    placeholder: BLURRED
                    formats: [WEBP]
                  )
                  fullscreen: gatsbyImageData(
                    placeholder: BLURRED
                    formats: [WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;
